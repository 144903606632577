//* External
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

//* App Custom
import { CustomIcon } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';

const InputLabel = ({ name, text, helper, required }) => (
  <label htmlFor={name} className="fw-bold">
    <div className="d-flex gap-2">
      <div>
        {tnl(text)}
        {required && (
          <span className="customInput_required ms-1 text-wrap">*</span>
        )}
      </div>
      {helper && (
        <OverlayTrigger
          key="auto"
          placement="auto"
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <p>{helper}</p>
              </Popover.Body>
            </Popover>
          }
        >
          <div className="cursor-pointer">
            <CustomIcon name="questionCircle" />
          </div>
        </OverlayTrigger>
      )}
    </div>
  </label>
);

InputLabel.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  helper: PropTypes.string,
  required: PropTypes.bool
};

export default InputLabel;
