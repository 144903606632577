//* External
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';

//* App Custom
import { CustomIcon } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import InputLabel from '../InputLabel/InputLabel';
import './inputDropzone.scss';

const InputDropzone = ({
  name,
  label,
  labelHelper,
  extensions = [],
  disabled = false,
  required = false,
  design = 'col-12',
  hookError,
  hookOnChange = () => {},
  onInputChange = () => {}
}) => {
  const [fileSelected, setFileSelected] = useState(null);
  const acceptedExtensions = extensions
    .map((extension) => `.${extension}`)
    .join(', ');

  const fileSize = useMemo(() => {
    if (fileSelected) {
      return Number((fileSelected.size / (1024 * 1024)).toFixed(2));
    } else return null;
  }, [fileSelected]);

  return (
    <div className={design}>
      <div className={`customInput ${disabled ? 'customInput_disabled' : ''}`}>
        <InputLabel
          name={name}
          text={label}
          helper={labelHelper}
          required={required}
        />
        <Dropzone
          disabled={disabled}
          accept={acceptedExtensions}
          onDrop={(files) => {
            setFileSelected(files[0]);
            hookOnChange(files[0]);
            onInputChange(files[0]);
          }}
          noDrag
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={`inputDropzone d-flex gap-2 ${
                disabled ? 'inputDropzone_disabled' : ''
              }`}
            >
              <div
                className="inputDropzone_trigger items_row_centered"
                {...getRootProps()}
              >
                <CustomIcon name="fileArrowUp" size="MD" />
                <input {...getInputProps()} hidden />
              </div>
              <div className="d-flex flex-column justify-content-center">
                {!fileSelected ? (
                  <div>
                    {tnl('components.input_dropzone_extensions')}
                    <span className="ps-1 fw-bold">{acceptedExtensions}</span>
                  </div>
                ) : (
                  tnl('components.input_dropzone_file_loaded')
                )}

                {fileSelected && (
                  <div className="inputDropzone_thumb d-flex gap-2 align-items-center">
                    <p>
                      {fileSelected?.path} - {fileSize}MB
                    </p>
                    <button
                      type="button"
                      disabled={disabled}
                      className="inputDropzone_thumb_btn"
                      onClick={() => {
                        setFileSelected(null);
                        hookOnChange(null);
                        onInputChange(null);
                      }}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
        {hookError?.message && (
          <p className="customInput_error">{tnl(hookError?.message)}</p>
        )}
      </div>
    </div>
  );
};

InputDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  extensions: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  design: PropTypes.string,
  hookError: PropTypes.shape({
    message: PropTypes.string
  }),
  onInputChange: PropTypes.func,
  hookOnChange: PropTypes.func
};

export default InputDropzone;
