//* External
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { Swiper as CustomSwiper, SwiperSlide } from 'swiper/react';

//* App Custom
import CustomIcon from '../CustomIcon/CustomIcon';
import './swiper.scss';

const defaultBreakpoints = {
  768: {
    slidesPerView: 3,
    initialSlide: 4,
    gap: -1
  },
  1024: {
    slidesPerView: 4,
    initialSlide: 4,
    gap: 0
  },
  1440: {
    slidesPerView: 5,
    initialSlide: 3,
    gap: 2
  }
};

const Swiper = ({
  data = [],
  breakpoints = defaultBreakpoints,
  activeItem = 0,
  component
}) => {
  const windowWidth = window.innerWidth;
  const [breakpoint, setBreakpoint] = useState(null);

  useEffect(() => {
    if (windowWidth <= 768) setBreakpoint(breakpoints[768]);
    else if (windowWidth > 768 && windowWidth <= 1024)
      setBreakpoint(breakpoints[1024]);
    else setBreakpoint(breakpoints[1440] || breakpoints[1024]);
  }, [activeItem]);

  return (
    <>
      {breakpoint && (
        <div role="swiper" className="d-flex gap-2" key={activeItem}>
          <div className="custom_swiperBtn_prev">
            <CustomIcon name="chevronLeft" />
          </div>
          <CustomSwiper
            className="custom_swiper"
            spaceBetween={10}
            slidesPerView={breakpoint.slidesPerView}
            initialSlide={breakpoint.initialSlide}
            navigation={{
              prevEl: '.custom_swiperBtn_prev',
              nextEl: '.custom_swiperBtn_next'
            }}
            modules={[Navigation]}
          >
            {data.map((item, index) => (
              <SwiperSlide
                key={`item_${index}`}
                onClick={() =>
                  setBreakpoint((prevState) => ({
                    ...prevState,
                    initialSlide: index - prevState.gap
                  }))
                }
              >
                {component(item)}
              </SwiperSlide>
            ))}
          </CustomSwiper>
          <div className="custom_swiperBtn_next">
            <CustomIcon name="chevronRight" />
          </div>
        </div>
      )}
    </>
  );
};

Swiper.propTypes = {
  data: PropTypes.array.isRequired,
  breakpoints: PropTypes.object,
  component: PropTypes.func.isRequired
};

export default Swiper;
