//* External
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

//* App Custom
import { Button, ButtonsList, CustomIcon } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';

const ConfirmationModal = ({
  content,
  iconName = 'question',
  iconColor = 'green-light',
  extraFooterBtns = [],
  cancelBtnTxt = 'buttons.close',
  acceptBtnTxt = 'buttons.accept',
  isDoingRequest = false,
  acceptBtnFn = () => {},
  onHide = () => {},
  size = 'lg'
}) => {
  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="p-3 d-flex flex-column align-items-center">
          <CustomIcon name={iconName} size="XL" color={iconColor} />
          <div className="text-center mt-2 mb-4">
            <p className="fw-bold" style={{ fontSize: 20 }}>
              {tnl(content.description)}
            </p>
            {content.extraDescription && <p>{tnl(content.extraDescription)}</p>}
          </div>
          <div className="d-flex flex-wrap justify-content-center gap-1">
            <Button
              variant="secondary"
              text={cancelBtnTxt}
              onClick={onHide}
              disabled={isDoingRequest}
            />
            <ButtonsList data={extraFooterBtns} />
            <Button
              text={acceptBtnTxt}
              onClick={acceptBtnFn}
              disabled={isDoingRequest}
              isDoingRequest={isDoingRequest}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string,
    extraDescription: PropTypes.string
  }).isRequired,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  extraFooterBtns: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['button', 'submit']),
      variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'danger']),
      border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
      title: PropTypes.string,
      text: PropTypes.string,
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      isDoingRequest: PropTypes.bool,
      fileToDownload: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  cancelBtnTxt: PropTypes.string,
  acceptBtnTxt: PropTypes.string,
  isDoingRequest: PropTypes.bool,
  acceptBtnFn: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['md', 'lg', 'xl', '2xl'])
};

export default ConfirmationModal;
