//* External
import PropTypes from 'prop-types';
import { Popover as BootstrapPopover, OverlayTrigger } from 'react-bootstrap';

//* App Custom
import { tnl } from '@i18nApp/i18n';
import './Popover.css';

const Popover = ({ title, body, trigger = ['hover', 'focus'], children }) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement="auto"
      rootClose
      overlay={
        <BootstrapPopover
          id="popover-basic"
          className="custom_popover px-2 py-2"
        >
          {title && (
            <BootstrapPopover.Header as="h3">
              {tnl(title)}
            </BootstrapPopover.Header>
          )}
          {body && <BootstrapPopover.Body>{body}</BootstrapPopover.Body>}
        </BootstrapPopover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

Popover.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object,
  trigger: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.object
};

export default Popover;
