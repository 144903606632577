//* External
import PropTypes from 'prop-types';

//* App Custom
import Button from '../Button';

const ButtonsList = ({ data }) => (
  <div className="d-flex flex-wrap gap-2">
    {data
      .filter((btn) => !btn.isHidden)
      .map((btn, index) => (
        <Button key={`button_${index}`} type={btn.type || 'button'} {...btn} />
      ))}
  </div>
);

Button.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['button', 'submit']),
      variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'danger']),
      border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
      title: PropTypes.string,
      text: PropTypes.string,
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      isDoingRequest: PropTypes.bool,
      fileToDownload: PropTypes.string,
      onClick: PropTypes.func
    })
  )
};

export default ButtonsList;
