import { Accordion, CustomIcon } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import SidebarItem from '@layoutsApp/UserAuthenticated/Sidebar/SidebarItem';
import { cleanArray } from './index';

//* Sidebar info validation
const sidebarRequestInfoValidation = (item, setNavigationErrors = () => []) => {
  const storeError = (item, setNavigationErrors = () => []) => {
    setNavigationErrors(tnl('toasts.sidebar_error', { label: item.label }));
  };

  if (item.children.length === 0 && !item.functional_name) {
    storeError(item, setNavigationErrors);
  } else {
    const newItem = {
      ...item,
      children: cleanArray(
        item.children.map((child) =>
          sidebarRequestInfoValidation(child, setNavigationErrors)
        )
      )
    };
    if (newItem.children.length === 0 && !newItem.functional_name) {
      storeError(newItem, setNavigationErrors);
    } else return newItem;
  }
};
export const sidebarRequestInfoValidated = ({
  data = [],
  setNavigationErrors = () => []
}) => {
  return cleanArray(
    data.map((item) => sidebarRequestInfoValidation(item, setNavigationErrors))
  );
};

//* Sidebar items render
const sidebarInfoHandler = (item, location, level) => {
  if (item.children.length === 0) {
    return (
      <div key={item.id} className={level > 0 ? 'sidebar_identation' : ''}>
        <SidebarItem data={item} location={location} />
      </div>
    );
  } else {
    return (
      <div
        key={`sidebar_parent_${item.id}`}
        className={level > 0 ? 'sidebar_identation' : ''}
      >
        <Accordion
          data={[item]}
          header={(item) => {
            return (
              <div className="d-flex align-items-center">
                <CustomIcon name={item.icon} />
                <p className="text-break ms-2">{item.label}</p>
              </div>
            );
          }}
          body={({ children }) => (
            <div className="d-flex flex-column gap-1 mt-1">
              {children.map((child) =>
                sidebarInfoHandler(child, location, level + 1)
              )}
            </div>
          )}
          type="light"
          isSidebar
        />
      </div>
    );
  }
};
export const sidebarItemsRender = (data = [], location) => {
  return (
    <div className="sidebar_items my-2">
      {data.map((item) => sidebarInfoHandler(item, location, 0))}
    </div>
  );
};

//* Private route configuration
const privateRouteItemHandler = (item) => {
  if (item.children.length === 0) return item;
  else
    return item.children.map((child) => privateRouteItemHandler(child)).flat();
};
export const privateRouteOptions = (data = []) => {
  return data
    .map((item) => {
      if (item.children.length === 0) return item;
      else
        return item.children
          .map((child) => privateRouteItemHandler(child))
          .flat();
    })
    .flat();
};
