//* External
import PropTypes from 'prop-types';
import { memo } from 'react';

//* App Custom
import { CustomIcon } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import InputLabel from '../InputLabel/InputLabel';
import '../inputs.scss';

const Input = ({
  type = 'text',
  name,
  label,
  labelHelper,
  placeholder,
  iconName,
  iconColor,
  step,
  defaultValue,
  disabled = false,
  required = false,
  design = 'col-12',
  hookError,
  onInputChange = () => {},
  hookOnChange = () => {}
}) => {
  return (
    <div
      className={`customInput ${design} ${
        disabled ? 'customInput_disabled' : ''
      }`}
    >
      {label && (
        <InputLabel
          name={name}
          text={label}
          helper={labelHelper}
          required={required}
        />
      )}
      <div className="customInput_container">
        {iconName && (
          <CustomIcon
            className="customInput_icon"
            name={iconName}
            color={iconColor || 'gray'}
          />
        )}
        <input
          type={type}
          id={name}
          placeholder={tnl(placeholder)}
          disabled={disabled}
          step={step}
          defaultValue={defaultValue}
          onChange={(e) => {
            const value = e.target.value;
            if (type === 'datetime-local') {
              hookOnChange(value);
              onInputChange(value);
            } else {
              hookOnChange(value);
              onInputChange(value);
            }
          }}
          onWheel={(e) => {
            e.target.blur();
            e.stopPropagation();
          }}
        />
      </div>
      {hookError?.message && (
        <p className="customInput_error">{tnl(hookError?.message)}</p>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'date',
    'datetime-local',
    'password',
    'time'
  ]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  design: PropTypes.string,
  hookError: PropTypes.shape({
    message: PropTypes.string
  }),
  onInputChange: PropTypes.func,
  hookOnChange: PropTypes.func
};

const MemoizeInput = memo(Input);
export default MemoizeInput;
