//* External
import PropTypes from 'prop-types';
import { Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//* App Custom
import { Button, ButtonsList, InputHandler } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import '../modals.scss';

const FormModal = ({
  title,
  inputs = () => [],
  submit = () => {},
  onHide = () => {},
  params = {},
  extraBody,
  extraFooterBtns = [],
  submitBtnStyle = 'general',
  submitBtnText = 'buttons.manage',
  size = 'md'
}) => {
  const { control, handleSubmit, watch, formState } = useForm();

  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
    >
      <Form onSubmit={handleSubmit(submit)}>
        <Modal.Header closeButton>
          {title && <h4>{tnl(title)}</h4>}
        </Modal.Header>
        <Modal.Body>
          {extraBody && extraBody}
          <div className="py-2 px-3">
            <Row>
              {inputs({ ...params, tnl, watch })
                .filter((input) => !input?.isHidden)
                .map((field, index) => (
                  <InputHandler
                    key={index}
                    {...field}
                    control={control}
                    disabled={field?.disabled}
                  />
                ))}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap justify-content-end gap-2 px-2">
            <Button variant="secondary" text="buttons.close" onClick={onHide} />
            <ButtonsList data={extraFooterBtns} />
            <Button
              type="submit"
              style={submitBtnStyle}
              text={submitBtnText}
              title={!formState.isValid ? 'titles.missing_required_inputs' : ''}
              disabled={!formState.isValid}
            />
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

FormModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputs: PropTypes.func,
  params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  extraFooterBtns: PropTypes.arrayOf(PropTypes.object),
  submit: PropTypes.func,
  submitBtnText: PropTypes.string,
  acceptBtnFn: PropTypes.func,
  acceptBtnTxt: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  section: PropTypes.string,
  size: PropTypes.string,
  submitBtnStyle: PropTypes.oneOf([
    'general',
    'outline-primary',
    'outline-danger',
    'link',
    'danger'
  ])
};

export default FormModal;
