//* External
import PropTypes from 'prop-types';

//* App Custom
import { tnl } from '@i18nApp/i18n';

const DataMapper = ({ data, columnsDesign = 'col-12 col-md-6 col-lg-4' }) => (
  <div className="d-flex flex-wrap">
    {data
      .filter((infoItem) => !infoItem?.isHidden)
      .map((item, index) => (
        <div
          key={index}
          className={`d-flex flex-wrap align-items-start ${columnsDesign}`}
        >
          <p className="fw-bold pe-2">{tnl(item.label)}:</p>
          <div className="fw-normal">{item.value}</div>
        </div>
      ))}
  </div>
);

DataMapper.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      isHidden: PropTypes.bool
    })
  ).isRequired,
  columnsDesign: PropTypes.string
};

export default DataMapper;
