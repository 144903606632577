export default {
  ROOT: '/',
  ERROR: '/404',

  //* Public Routes
  LOGIN: '/login',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',

  //* Private Routes
  PLATFORM: '/platform',
  ROOMS: '/platform/rooms',
  CHAIR: '/platform/chair',
  PATIENTS: '/platform/patients',
  PATIENT: '/platform/patient',
  SURVEY_LIST: '/platform/survey_list',
  SURVEY: '/platform/survey'
};
