//* External
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//* App Custom
import { ErrorBoundaryFront } from '@componentsApp/common/index';
import { ROUTES } from '@constantsApp/index';
import { useLocalStorage } from '@hooksApp/index';
import ModuleWrapper from './ModuleWrapper/ModuleWrapper';

const PrivateRoute = ({ module, children }) => {
  const {
    userData,
    recaptcha,
    navigationErrors,
    removeRecaptcha,
    removeNavigationErrors
  } = useLocalStorage();

  useEffect(() => {
    if (recaptcha) removeRecaptcha();
    else if (navigationErrors.length > 0) {
      if (userData.is_superuser) {
        navigationErrors.map((error) => toast.error(error));
      }
      removeNavigationErrors();
    }
  }, []);

  return (
    <>
      {Object.keys(omit(userData, ['domain'])).length > 0 ? (
        <ErrorBoundaryFront>
          <ModuleWrapper data={module}>{children}</ModuleWrapper>
        </ErrorBoundaryFront>
      ) : (
        <Navigate to={ROUTES.ROOT} />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  module: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default PrivateRoute;
