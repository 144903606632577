import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cycleData: {
    pk: null,
    patient_id: null,
    plan: null,
    applications_quantity: null,
    medical_order_ids: [],
    applications: [],
    specialty: null,
    name: ''
  },
  temporalApplication: {
    start_date_time: '',
    end_date_time: '',
    interval: [],
    chair: null,
    protocol: null,
    name: '',
    toBeEdited: false,
    pk: null,
    application_status: null,
    premedications: [],
    infuserbooking_set: {
      infusion_interval: [],
      start_date_time: '',
      end_date_time: '',
      infuser_type: null,
      removal_hours: null
    }
  },
  patientData: {},
  protocols: [],
  planTypes: [],
  rooms: [],
  premedications: []
};

export const cycleSlice = createSlice({
  name: 'cycle',
  initialState,
  reducers: {
    setProtocols: (state, action) => {
      state.protocols = action.payload;
    },
    setPlanTypes: (state, action) => {
      state.planTypes = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setPatientData: (state, action) => {
      state.patientData = action.payload;
    },
    setCycleData: (state, action) => {
      const newCycleDataInfo = {
        ...state.cycleData,
        ...action.payload
      };
      state.cycleData = newCycleDataInfo;
    },
    setTemporalApplication: (state, action) => {
      const newTemporalApplication = {
        ...state.temporalApplication,
        ...action.payload
      };
      state.temporalApplication = newTemporalApplication;
    },
    setInfuserBooking: (state, action) => {
      const newInfuserBooking = {
        ...state.temporalApplication.infuserbooking_set,
        ...action.payload
      };
      state.temporalApplication.infuserbooking_set = newInfuserBooking;
    },
    setPremedications: (state, action) => {
      state.premedications = action.payload;
    },
    resetTemporalApplication: (state) => {
      state.temporalApplication = initialState.temporalApplication;
    },
    resetMedicalOrdersIds: (state) => {
      state.cycleData.medical_order_ids = [];
    },
    resetPremedications: (state) => {
      state.premedications = [];
    },
    resetCycleState: () => initialState
  }
});

export const {
  resetMedicalOrdersIds,
  resetCycleState,
  resetTemporalApplication,
  resetPremedications,
  setCycleData,
  setPlanTypes,
  setProtocols,
  setRooms,
  setTemporalApplication,
  setPremedications,
  setPatientData,
  setInfuserBooking
} = cycleSlice.actions;
export default cycleSlice.reducer;
