//* External
import PropTypes from 'prop-types';

//* App Custom
import { CSS_COLORS } from '@constantsApp/colors';
import { tnl } from '@i18nApp/i18n';

const Indicator = ({ color, label, extraLabel }) => (
  <div className="d-flex align-items-center">
    <div
      style={{
        backgroundColor: CSS_COLORS[color],
        width: 20,
        height: 20,
        borderRadius: 50
      }}
      className="d-flex align-items-center"
    />
    {extraLabel !== null && <p className="ms-2 fw-bold">{extraLabel}</p>}
    <p className="ms-2">{tnl(label)}</p>
  </div>
);

Indicator.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  extraLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Indicator;
