//* External
import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';

//* App Custom
import IconEB from '@assetsApp/ErrorBoundaryFront.svg';
import { Button } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import './errorBoundaryFront.scss';

function ErrorFallback({ error, resetErrorBoundary }) {
  const stackTrace = error.stack || '';
  const stackLines = stackTrace.split('\n');
  const firstStackLine = stackLines[1] || '';
  const importDeployError = 'Failed to fetch dynamically imported module';

  if (error.message.includes(importDeployError)) {
    window.location.reload();
  }

  // Extract file and line information from the stack trace
  const matchResult = /\s*at\s.*\((.*):(\d+):(\d+)\)/.exec(firstStackLine);

  const fileName = matchResult
    ? matchResult[1]
        .substring(matchResult[1].lastIndexOf('/') + 1)
        .split('?')[0]
    : 'Unknown file';

  const lineNumber = matchResult ? matchResult[2] : 'Unknown line';

  useEffect(() => {
    console.log({ message: error.message, fileName, lineNumber });
  }, []);

  return (
    <div className="eb_container">
      <div>
        <Image
          className="eb_container_image"
          src={IconEB}
          alt="img_eb_front"
          fluid
        />
        <h4 className="fw-bold">
          {tnl('components.errorBoundary_front_title')}
        </h4>
      </div>
      <div>
        <Button
          text="buttons.app_reload"
          onClick={() => resetErrorBoundary()}
        />
      </div>
    </div>
  );
}

const ErrorBoundaryFront = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
};

export default ErrorBoundaryFront;
