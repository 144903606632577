//* External
import PropTypes from 'prop-types';

//* App Custom
import { Badge } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import './Timeline.scss';

const Timeline = ({ data = [] }) => {
  return (
    <>
      {data.length > 0 ? (
        <div className="timeline-scrollable-container">
          <div className="timeline">
            <div className="timeline-line"></div>
            <div className="timeline-container">
              {data.map((info, index) => (
                <div
                  data-testid="timeline-item"
                  className="timeline-item"
                  key={`item_${index}`}
                >
                  <div className="item-separator"></div>
                  <div className="w-100">
                    <Badge
                      value={info.badge}
                      clickable={info?.clickable}
                      onClick={info?.onClick}
                    />
                  </div>
                  {info.items.map((timelineData) => (
                    <p key={`data_${timelineData}`}>{timelineData}</p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center">{tnl('labels.timeline_with_no_data')}</p>
      )}
    </>
  );
};

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      badge: PropTypes.exact({
        color: PropTypes.string,
        label: PropTypes.string
      }),
      items: PropTypes.arrayOf(PropTypes.string),
      clickable: PropTypes.bool,
      onClick: PropTypes.func
    })
  )
};

export default Timeline;
