//* External
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//* App Custom
import {
  ErrorBoundaryBack,
  ErrorBoundaryFront
} from '@componentsApp/common/index';
import Routes from '@routesApp/Routes';
import './App.scss';

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_WEB_SITE_KEY}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover={true}
        draggable={false}
      />
      <ErrorBoundaryBack>
        <ErrorBoundaryFront>
          <Routes />
        </ErrorBoundaryFront>
      </ErrorBoundaryBack>
    </GoogleReCaptchaProvider>
  );
};

export default App;
