//* External
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

//* App Custom
import Icon401 from '@assetsApp/401.svg';
import { Button } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import { cleanError } from '@reduxApp/index';
import './errorBoundaryBack.scss';

const ErrorBoundaryBack = ({ children }) => {
  const dispatch = useDispatch();
  const { errorRequest } = useSelector((state) => state.commonState);
  const [tokenInvalid, setTokenInvalid] = useState(false);

  useEffect(() => {
    if (errorRequest) {
      const errorInfo = (type) =>
        errorRequest?.[type] || errorRequest?.data?.[type];
      const isTokenNotValid = errorInfo('detail') === 'Invalid token.';
      if (isTokenNotValid) setTokenInvalid(true);
      else {
        setTokenInvalid(false);
        toast.error(tnl(errorInfo('detail')));
        dispatch(cleanError());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRequest]);

  const clearAppStorage = () => {
    dispatch(cleanError());
    localStorage.clear();
    window.location.replace('/');
  };

  return (
    <>
      {tokenInvalid ? (
        <div className="tokenInvalid text-center">
          <div className="tokenInvalid_container">
            <Image src={Icon401} alt="img_401" width="25%" fluid />
            <div className="my-4">
              <h1 className="fw-bold">
                {tnl('components.errorBoundary-title')}
              </h1>
              <p>{tnl('components.errorBoundary-detail')}</p>
            </div>
            <Button
              variant="secondary"
              text="buttons.login"
              onClick={clearAppStorage}
              fullWidth
            />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

ErrorBoundaryBack.propTypes = {
  children: PropTypes.element
};

export default ErrorBoundaryBack;
