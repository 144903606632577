export { default as APIS, FAILED_SHOWDATA_REQUEST_STATE } from './apis';
export { emptyInputOptions, formOptions } from './formOptions';
export { default as PERMISSIONS } from './permissions';

export const FILE_EXTENSIONS = {
  CSV: 'csv',
  JPG: 'jpg',
  PDF: 'pdf',
  PNG: 'png',
  RAR: 'rar',
  TXT: 'txt',
  XLS: 'xls',
  XLSX: 'xlsx',
  ZIP: 'zip'
};

export * from './colors';
export * from './icons';
export * from './inputRules';
export { default as ROUTES } from './routes';
