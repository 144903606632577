import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDoingRequest: false,
  errorRequest: null,
  selectedDate: null
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    doingRequest: (state) => {
      state.isDoingRequest = true;
    },
    requestFinalized: (state) => {
      state.isDoingRequest = false;
    },
    saveUserError: (state, action) => {
      const error = action.payload;
      let errorToDisplay = {
        code: 'unknownError',
        detail: 'unkown_value.request-error'
      };
      if (error?.detail || error?.data?.detail) errorToDisplay = error;
      else if (error?.Error)
        errorToDisplay = { code: 'bhError', detail: error?.Error };

      state.errorRequest = errorToDisplay;
    },
    saveServerError: (state) => {
      state.errorRequest = {
        code: 'serverError',
        detail: 'unkown_value.server-error'
      };
    },
    cleanError: (state) => {
      state.errorRequest = null;
    },
    saveSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    }
  }
});

export const {
  cleanError,
  doingRequest,
  requestFinalized,
  saveUserError,
  saveServerError,
  saveSelectedDate,
} = commonSlice.actions;
export default commonSlice.reducer;
