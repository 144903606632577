import { REGEX } from './regex';

export const INPUT_REQUIRED = {
  required: {
    value: true,
    message: 'form_errors.input_is_required'
  }
};

export const EMAIL_PATTERN = {
  pattern: {
    value: REGEX.email,
    message: 'form_errors.email_pattern'
  }
};

export const PASSWORD_PATTERN = {
  pattern: {
    value: REGEX.password,
    message: 'form_errors.password_pattern'
  }
};
