//* External
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

//* App Custom
import { Button, ButtonsList } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import '../modals.scss';

const BasicModal = ({
  title,
  titleExtraInfo = '',
  body,
  extraFooterBtns = [],
  onHide = () => {},
  size = 'md'
}) => {
  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
    >
      <Modal.Header closeButton>
        {title && <h4>{`${tnl(title)} ${titleExtraInfo}`}</h4>}
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-wrap justify-content-end gap-2 px-2">
          <Button variant="secondary" text="buttons.close" onClick={onHide} />
          <ButtonsList data={extraFooterBtns} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

BasicModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleExtraInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  extraFooterBtns: PropTypes.arrayOf(PropTypes.object),
  onHide: PropTypes.func.isRequired,
  size: PropTypes.string
};

export default BasicModal;
