export const BackendOption = {
  AFFIRMATIVE: 'success',
  APPLIED: 'success',
  APPLICATION_MODULE: 'success',
  CLOSED: 'fail',
  CANCELED: 'fail',
  CANCELED_OVERRIDE: 'fail',
  CANCELED_BY_CYCLE: 'fail',
  DISCARDED: 'fail',
  DISPENSED: 'success',
  DOES_NOT_APPLY: 'info',
  ERROR: 'fail',
  EPS: 'info',
  FINALIZED: 'success',
  FINALIZED_WITH_ERROR: 'fail',
  FINISHED: 'success',
  INACTIVE: 'fail',
  IN_MANAGEMENT: 'info',
  INITIALIZED: 'info',
  INITIATED: 'info',
  INVOICED_PRINTED: 'success',
  NEGATIVE: 'fail',
  NOT_PBS: 'success',
  MANAGED: 'success',
  MASSIVE_LOAD: 'info',
  OPEN: 'info',
  OCCUPIED: 'fail',
  PAC: 'success',
  PARTICULAR: 'warning',
  PATIENT_MODULE: 'success',
  PBS: 'fail',
  PENDING: 'warning',
  PROCESING: 'warning',
  PROCESSED: 'success',
  RECEIVED: 'success',
  RESOLVED: 'success',
  RESCHEDULED: 'warning',
  RETURNED: 'fail',
  SCHEDULED: 'success',
  TO_BE_CANCELED: 'fail',
  VALIDATING: 'info',
  WAITING: 'info',
  UNMANAGED: 'info',
  UNKNOWN: 'warning'
};
