//* External
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//* App Custom
import { CSS_COLORS } from '@constantsApp/index';
import { tnl } from '@i18nApp/i18n';
import Indicator from '../Indicator/Indicator';

const Percentage = ({
  value = 0,
  title = 'components.percentage-title',
  freeText = 'components.percentage-free',
  usedText = 'components.percentage-occupied',
  freeColor = 'green-light',
  usedColor = 'blue-dark',
  size = 110
}) => {
  return (
    <div className="p-3 border rounded h-100 d-flex flex-column justify-content-center">
      <p className="fw-bold mb-2">{tnl(title)}</p>
      <div className="d-flex align-items-center gap-3">
        <div
          className="d-flex justify-content-center"
          data-testid="percentage_id"
          style={{ width: size, height: size }}
        >
          <CircularProgressbar
            value={value}
            text={`${value}%`}
            strokeWidth={7}
            styles={buildStyles({
              pathColor: CSS_COLORS[freeColor],
              trailColor: CSS_COLORS[usedColor],
              textColor: 'var(--black)'
            })}
          />
        </div>
        <div className="d-flex flex-column gap-2 mt-2">
          <Indicator color={usedColor} label={freeText} />
          <Indicator color={freeColor} label={usedText} />
        </div>
      </div>
    </div>
  );
};

Percentage.propTypes = {
  value: PropTypes.number.isRequired,
  usedSpaceColor: PropTypes.string,
  freeSpaceColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.number
};

export default Percentage;
