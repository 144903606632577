//* External
import PropTypes from 'prop-types';
import { useState } from 'react';

//* App Custom
import { DataMapper } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';
import { verifyData } from '@utilsApp/index';
import BasicModal from '../BasicModal/BasicModal';
import './MoreInfoModal.scss';

const MoreInfoModal = ({
  title = 'labels.more_info_modal.title',
  text,
  labelData,
  maxString = 25,
  size = 'lg'
}) => {
  const [showMore, setShowMore] = useState(false);
  const RenderModal = (
    <>
      {showMore && (
        <BasicModal
          title={title}
          body={
            <>
              {labelData ? (
                <DataMapper data={labelData} />
              ) : (
                <div className="my-1 px-3">{text}</div>
              )}
            </>
          }
          onHide={() => setShowMore(false)}
          size={size}
        />
      )}
      <p className="moreInfoModal py-2">
        {`${text?.substr(0, maxString)}... `}
        <span onClick={() => setShowMore(true)}>
          {tnl('labels.more_info_modal.btn')}
        </span>
      </p>
    </>
  );
  return <>{text?.length > maxString ? RenderModal : verifyData([text])}</>;
};

MoreInfoModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default MoreInfoModal;
