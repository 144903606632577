//* External
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

//* App Custom 
import commonSlice from './slices/commonSlice';
import cycleSlice from './slices/cycleSlice';

export const store = configureStore({
  reducer: combineReducers({
    commonState: commonSlice,
    cycleState: cycleSlice
  }),
  middleware: [thunk]
});
