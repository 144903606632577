//* External
import PropTypes from 'prop-types';

//* App Custom
import { tnl } from '@i18nApp/i18n';
import ButtonsList from '../Button/ButtonsList/ButtonsList';
import './wrapper.scss';

const Wrapper = ({ title, border = 'full', buttons = [], children }) => {
  return (
    <div
      data-testid="wrapper"
      className={`wrapper p-3 wrapper_border_${border}`}
    >
      {(title || buttons.length > 0) && (
        <div className="d-flex flex-wrap gap-1 gap-md-3">
          {title && <h5 className="fw-bold">{tnl(title)}</h5>}
          {buttons.length > 0 && <ButtonsList data={buttons} />}
        </div>
      )}
      <>{children}</>
    </div>
  );
};

Wrapper.propTypes = {
  title: PropTypes.string,
  border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
  buttons: PropTypes.arrayOf(
    PropTypes.exact({
      type: PropTypes.oneOf(['button', 'submit']),
      variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'danger']),
      border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
      title: PropTypes.string,
      text: PropTypes.string,
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      isDoingRequest: PropTypes.bool,
      fileToDownload: PropTypes.string,
      onClick: PropTypes.func,
      isHidden: PropTypes.bool
    })
  ),
  children: PropTypes.node.isRequired
};

export default Wrapper;
