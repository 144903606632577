export const CSS_COLORS = {
  black: 'var(--black)',
  gray: 'var(--gray)',
  'gray-light': 'var(--gray-light)',
  'blue-light': 'var(--blue-light)',
  'blue-dark': 'var(--blue-dark)',
  'green-light': 'var(--green-light)',
  'green-dark': 'var(--green-dark)',
  'red-light': 'var(--red-light)',
  'red-dark': 'var(--red-dark)',
  white: 'var(--white)',
  'yellow-light': 'var(--yellow-light)',
  'yellow-dark': 'var(--yellow-dark)',
  'app-primary-color': 'var(--app-primary-color)',
  'app-secondary-color': 'var(--app-secondary-color)',
  'app-tertiary-color': 'var(--app-tertiary-color)',
  'app-disabled-color': 'var(--app-disabled-color)'
};
