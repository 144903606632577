import { CustomIcon } from '@componentsApp/common/index';
import { tnl } from '@i18nApp/i18n';

const NoDataAvailable = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center my-3 w-100">
      <div className="mb-2">
        <CustomIcon
          name="infoCircle"
          size="XL"
          className="noDataAvailable_icon"
          color="gray"
        />
      </div>
      <div className="text-center">
        <p className="fw-bold">{tnl('components.noData_title')}</p>
        <p>{tnl('components.noData_desc')}</p>
      </div>
    </div>
  );
};

export default NoDataAvailable;
