import PropTypes from 'prop-types';

const LinkButtonWrapper = ({ link, disabled = false, children }) => {
  if (link)
    return (
      <a
        rel="noopener noreferrer"
        target="_blank"
        role="link_download"
        href={link}
        style={{
          cursor: disabled ? 'initial' : 'pointer',
          pointerEvents: disabled ? 'none' : 'initial'
        }}
        download
      >
        {children}
      </a>
    );
  else return children;
};

LinkButtonWrapper.propTypes = {
  link: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default LinkButtonWrapper;
