//* External
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Badge as RB_Badge } from 'react-bootstrap';

//* App Custom
import { tnl } from '@i18nApp/i18n';
import { BackendOption } from './BadgeData';
import './badge.scss';

const Badge = ({
  value,
  active = false,
  clickable = false,
  onClick = () => {}
}) => {
  const badgeStyle = useMemo(() => {
    let color;
    let label;
    let cursor = 'cursor-initial';

    if (value) {
      if (typeof value === 'string') {
        color = BackendOption[value] || 'app';
        label = value;
      } else {
        color = value.color;
        label = value.label;
      }
    } else {
      color = 'app';
      label = 'UNKNOWN';
    }

    let colorFormatted = `badge_${color}`;
    if (active) {
      colorFormatted = `${colorFormatted}_active`;
    } else if (clickable) {
      cursor = `cursor-pointer`;
      colorFormatted = `${colorFormatted} ${colorFormatted}_clickable`;
    }
    return { color: colorFormatted, label, cursor };
  }, [value, active, clickable]);

  return (
    <div className="badge_container">
      <RB_Badge
        role="badge"
        className={`${badgeStyle.color} ${badgeStyle.cursor} py-2 px-3`}
        onClick={onClick}
      >
        {typeof badgeStyle.label === 'string' ? (
          <p>{tnl(badgeStyle.label)}</p>
        ) : (
          badgeStyle.label
        )}
      </RB_Badge>
    </div>
  );
};

Badge.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.exact({
      color: PropTypes.string,
      label: PropTypes.string
    })
  ]),
  active: PropTypes.bool,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

export default Badge;
