//* External
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';

//* App Custom
import { tnl } from '@i18nApp/i18n';
import './table.scss';

const Table = ({ data = [], columns = [], conditionalRowStyles }) => {
  const verifyColumnsData = useMemo(() => {
    return columns
      .filter((column) => !column?.isHidden)
      .map((column) => ({
        name: (
          <>{column.name && <p className="text-wrap">{tnl(column.name)}</p>}</>
        ),
        cell: column.cell,
        minWidth: column?.minWidth || '120px'
      }));
  }, [columns]);

  return (
    <div className="customTable searchRequest">
      <DataTable
        columns={verifyColumnsData}
        data={data}
        responsive
        striped
        noDataComponent={<p>{tnl('components.datatable_no_data')}</p>}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      cell: PropTypes.func.isRequired,
      minWidth: PropTypes.string,
      isHidden: PropTypes.bool
    })
  ).isRequired,
  conditionalRowStyles: PropTypes.object
};

export default Table;
