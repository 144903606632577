//* Externaly6
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Navigate } from 'react-router-dom';

//* App Custom
import { ErrorBoundaryFront } from '@componentsApp/common/index';
import { ROUTES } from '@constantsApp/index';
import { useLocalStorage } from '@hooksApp/index';

const PublicRoute = ({ children }) => {
  const { userData, recaptcha, setRecaptcha } = useLocalStorage();

  return (
    <>
      {Object.keys(omit(userData, ['domain'])).length > 0 ? (
        <Navigate to={ROUTES.PLATFORM} />
      ) : (
        <ErrorBoundaryFront>
          <GoogleReCaptcha
            onVerify={(token) => {
              if (!recaptcha) setRecaptcha(token);
            }}
          />
          {children}
        </ErrorBoundaryFront>
      )}
    </>
  );
};

PublicRoute.propTypes = {
  children: PropTypes.object.isRequired
};

export default PublicRoute;
